import { Injectable } from '@angular/core';

// eslint-disable-next-line @typescript-eslint/ban-types
declare let gtag: Function;

@Injectable({
  providedIn: 'root'
})
export class GoogleAnalyticsService {

  constructor() { }

    sendAnalytics(): void {
        gtag('config', 'G-8CY9V7JDG8',
            {
                page_location: window.location.href,
                send_page_view: false
            }
        );
    }
}
