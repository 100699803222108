<main class="main-content theme-new-in-the-netherlands" [class.arabic]="selectedLanguage === LanguageChoice.ARABIC">
    <section class="section section-category-header">
        <div class="st-inner">
            <div class="container">
                <div class="st-content">
                    <h1 class="page-title h2 split-title" [class.arabic]="selectedLanguage === LanguageChoice.ARABIC">
                        <span class="line-1 f1 mb-0">{{'NEW_IN_THE_NETHERLANDS' | translate}}</span>
                        <span class="line-2 h1 t-lower">{{'AND_ATTENDING_SCHOOL' | translate}}</span>
                    </h1>
                    <div class="language-wrapper">
                        <div class="left-wrapper">
                            <a routerLink="/nieuw-in-nederland/en" class="language">
                                <img src="assets/img/homepage/flag-buttons/ENGELS.svg" alt="" class="flag">
                                <span>English</span>
                                <img src="assets/img/homepage/flag-buttons/arrow-right.svg" alt="" class="arrow">
                            </a>
                            <a routerLink="/nieuw-in-nederland/nl" class="language">
                                <img src="assets/img/homepage/flag-buttons/NEDERLANDS.svg" alt="" class="flag">
                                <span>Nederlands</span>
                                <img src="assets/img/homepage/flag-buttons/arrow-right.svg" alt="" class="arrow">
                            </a>
                            <a routerLink="/nieuw-in-nederland/ar" class="language">
                                <img src="assets/img/homepage/flag-buttons/ARABISCH.svg" alt="" class="flag">
                                <span class="arabic">عربي</span>
                                <img src="assets/img/homepage/flag-buttons/arrow-right.svg" alt="" class="arrow">
                            </a>
                            <a routerLink="/nieuw-in-nederland/tu" class="language">
                                <img src="assets/img/homepage/flag-buttons/TURKS.svg" alt="" class="flag">
                                <span>Türkçe</span>
                                <img src="assets/img/homepage/flag-buttons/arrow-right.svg" alt="" class="arrow">
                            </a>
                        </div>
                        <div class="right-wrapper">
                            <a routerLink="/nieuw-in-nederland/bu" class="language">
                                <img src="../../assets/img/homepage/flag-buttons/BULGAARS.svg" alt="" class="flag">
                                <span>Български</span>
                                <img src="assets/img/homepage/flag-buttons/arrow-right.svg" alt="" class="arrow">
                            </a>
                            <a routerLink="/nieuw-in-nederland/po" class="language">
                                <img src="assets/img/homepage/flag-buttons/POOLS.svg" alt="" class="flag">
                                <span>Polski</span>
                                <img src="assets/img/homepage/flag-buttons/arrow-right.svg" alt="" class="arrow">
                            </a>
                            <a routerLink="/nieuw-in-nederland/uk" class="language">
                                <img src="assets/img/homepage/flag-buttons/OEKRAIENS.svg" alt="" class="flag">
                                <span>Українська</span>
                                <img src="assets/img/homepage/flag-buttons/arrow-right.svg" alt="" class="arrow">
                            </a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>

    <section class="section section-category-article type-a">
        <div class="st-inner">
            <div class="container">
                <div class="col-right">
                    <img src="assets/img/photos/newcomers/image_01.png" alt="" class="image-right">
                </div>

                <div class="col-left indent js-mh">
                    <h2 class="h2">{{'ATTENDING_SCHOOL_IN_THE_NETHERLANDS' | translate}}</h2>
                    <div class="article-output">
                        <p>{{'ATTENDING_SCHOOL_IN_THE_NETHERLANDS.body' | translate}}</p>
                    </div>
                </div>
            </div>
        </div>
    </section>

    <section class="section section-category-text type-a">
        <div class="st-inner">
            <div class="container">
                <div class="row">
                    <div class="col-md-1 col-lg-2" *ngIf="selectedLanguage !== LanguageChoice.ARABIC"></div>
                    <div class="col-md-10 col-lg-9 c-article-list">
                        <article class="article-item">
                            <h2 class="article-title h2">{{'PRIMARY_EDUCATION' | translate}}</h2>
                            <div class="article-output">
                                <p>{{'PRIMARY_EDUCATION.BODY' | translate}} <a href="/categorie/aanmelden-als-zij-instromer">Scholenwijzer Den Haag</a>.</p>
                                <h4>{{'NEWCOMER_CLASS' | translate}}</h4>
                                <p>{{'NEWCOMER_CLASS.BODY_1' | translate}}</p>
                                <p>{{'NEWCOMER_CLASS.BODY_2' | translate}}</p>
                                <ul>
                                    <li><p>{{'NEWCOMER_LIST_ITEM_1' | translate}}</p></li>
                                    <li><p>{{'NEWCOMER_LIST_ITEM_2' | translate}}</p></li>
                                    <li><p>{{'NEWCOMER_LIST_ITEM_3' | translate}}</p></li>
                                    <li><p>{{'NEWCOMER_LIST_ITEM_4' | translate}}</p></li>
                                </ul>
                                <p>{{'NEWCOMER_CLASS_BODY_3' | translate}}</p>
                                <p>{{'NEWCOMER_CLASS_BODY_4' | translate}}</p>
                                <a *ngIf="selectedLanguage === LanguageChoice.DUTCH" class="register-button" routerLink="/signup-newcomers/nl">{{'REGISTER_NOW' | translate}} <img src="assets/img/homepage/flag-buttons/arrow-right-white.svg" alt=""></a>
                                <a *ngIf="selectedLanguage !== LanguageChoice.DUTCH" class="register-button" routerLink="/signup-newcomers/en">{{'REGISTER_NOW' | translate}} <img src="assets/img/homepage/flag-buttons/arrow-right-white.svg" alt=""></a>
                            </div>
                        </article>
                    </div>
                    <div class="col-md-1 col-lg-2" *ngIf="selectedLanguage === LanguageChoice.ARABIC"></div>
                </div>
            </div>
        </div>
    </section>

    <section class="section section-category-text type-b">
        <div class="st-inner">
            <div class="container">
                <div class="row">
                    <div class="col-md-4 col-lg-5" *ngIf="selectedLanguage !== LanguageChoice.ARABIC"></div>
                    <div class="col-md-7 col-lg-6 c-article-list">
                        <article class="article-item">
                            <h2 class="article-title h2">{{'SECONDARY_EDUCATION' | translate}}</h2>
                            <div class="article-output">
                                <h4>{{'NEWCOMER_CLASS' | translate}}</h4>
                                <p>{{'SECONDARY_EDUCATION_BODY' | translate}} <a href="/categorie/vo/map?properties=18">{{'HERE' | translate}}</a>.</p>
                                <p class="link-title">{{'SECONDARY_EDUCATION_LINKS' | translate}}</p>
                                <div><a href="https://www.johandewittscholengroep.nl/en/information/sign-up-isk" target="_blank">{{'SECONDARY_EDUCATION_LINK_1' | translate}}</a></div>
                                <div><a href="https://www.johandewittscholengroep.nl/informatie/aanmelden-isk" target="_blank">{{'SECONDARY_EDUCATION_LINK_2' | translate}}</a></div>
                            </div>
                        </article>
                    </div>
                    <div class="col-md-4 col-lg-5" *ngIf="selectedLanguage === LanguageChoice.ARABIC"></div>
                </div>
            </div>
        </div>
    </section>

    <section class="section section-category-text type-c">
        <div class="st-inner">
            <div class="container">
                <div class="row">
                    <div class="col-md-1 col-lg-2" *ngIf="selectedLanguage !== LanguageChoice.ARABIC"></div>
                    <div class="col-md-10 col-lg-9 c-article-list">
                        <article class="article-item">
                            <h2 class="article-title h2">{{'INTERNATIONAL_SCHOOLS' | translate}}</h2>
                            <div class="article-output">
                                <p>{{'INTERNATIONAL_SCHOOLS_BODY' | translate}} <a href="https://www.thehagueinternationalcentre.nl/studying-in-the-hague-region" target="_blank">{{'THE_HAGUE_INTERNATIONAL_CENTRE' | translate}}</a></p>
                            </div>
                        </article>
                    </div>
                    <div class="col-md-1 col-lg-2" *ngIf="selectedLanguage === LanguageChoice.ARABIC"></div>
                </div>
            </div>
        </div>
    </section>

    <section class="section section-category-article type-b">
        <div class="st-inner">
            <div class="container">
                <div class="col-left">
                    <img src="assets/img/photos/newcomers/image_02.png" alt="" class="image-left">
                </div>

                <div class="col-right indent js-mh">
                    <h2 class="h2">{{'IS_YOUR_CHILD_YOUNGER_THAN_4_YEARS_OLD' | translate}}</h2>
                    <div class="article-output">
                        <p>{{'CHECK_BELOW' | translate}}</p>
                        <div class="flex-wrapper">
                            <img src="assets/img/homepage/flag-buttons/arrow-right.svg" alt="" *ngIf="selectedLanguage !== LanguageChoice.ARABIC">
                            <a href="/categorie/Kinderdagopvang%20%280-4%20jaar%29">{{'CHILDCARE' | translate}}</a>
                            <img src="assets/img/homepage/flag-buttons/arrow-right.svg" alt="" *ngIf="selectedLanguage === LanguageChoice.ARABIC" class="arrow-left">
                        </div>
                           <div class="flex-wrapper">
                               <img src="assets/img/homepage/flag-buttons/arrow-right.svg" alt="" *ngIf="selectedLanguage !== LanguageChoice.ARABIC">
                               <a href="/artikel/voorschoolse-educatie">{{'PRESCHOOL' | translate}}</a>
                               <img src="assets/img/homepage/flag-buttons/arrow-right.svg" alt="" *ngIf="selectedLanguage === LanguageChoice.ARABIC" class="arrow-left">
                           </div>
                           <div class="flex-wrapper">
                               <img src="assets/img/homepage/flag-buttons/arrow-right.svg" alt="" *ngIf="selectedLanguage !== LanguageChoice.ARABIC">
                               <a href="/categorie/aanmelden-bij-de-basisschool">{{'SCHOOL_CHOICE' | translate}}</a>
                               <img src="assets/img/homepage/flag-buttons/arrow-right.svg" alt="" *ngIf="selectedLanguage === LanguageChoice.ARABIC" class="arrow-left">
                           </div>
                           <div class="flex-wrapper">
                               <img src="assets/img/homepage/flag-buttons/arrow-right.svg" alt="" *ngIf="selectedLanguage !== LanguageChoice.ARABIC">
                               <a href="/categorie/po">{{'PRIMARY_SCHOOL' | translate}}</a>
                               <img src="assets/img/homepage/flag-buttons/arrow-right.svg" alt="" *ngIf="selectedLanguage === LanguageChoice.ARABIC" class="arrow-left">
                           </div>
                           <div class="flex-wrapper">
                               <img src="assets/img/homepage/flag-buttons/arrow-right.svg" alt="" *ngIf="selectedLanguage !== LanguageChoice.ARABIC">
                               <a href="/categorie/vo">{{'SECONDARY_SCHOOL' | translate}}</a>
                               <img src="assets/img/homepage/flag-buttons/arrow-right.svg" alt="" *ngIf="selectedLanguage === LanguageChoice.ARABIC" class="arrow-left">
                           </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
</main>
