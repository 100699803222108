import {Component, HostListener, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {
    catchError,
    debounceTime,
    distinctUntilChanged,
    filter,
    switchMap,
} from 'rxjs/operators';
import {environment} from '../../environments/environment';
import {HttpClient} from '@angular/common/http';
import {EMPTY, from, fromEvent, merge, of, Subject, Subscription} from 'rxjs';
import {notNull} from '../shared/Utils';
import {ZipcodeService} from '../shared/services/zipcode.service';
import {NouiFormatter, NouisliderComponent} from 'ng2-nouislider';
import {UntypedFormControl, UntypedFormGroup, Validators} from '@angular/forms';
import {DecimalPipe} from '@angular/common';
import {CategoryService, CategoryType} from '../shared/services/category.service';
import {FavouriteService} from '../shared/services/favourite.service';
import {LocationService} from '../shared/services/location.service';
import {SeoService} from '../shared/services/seo.service';
import {RouterHelperService} from '../shared/services/routerHelper.service';
import { MapService, Location, LocationType } from '../shared/services/map.service';
import {GoogleAnalyticsService} from "../shared/services/google-analytics.service";

export interface Option {
    id: number | string;
    name: string;
    explanation?: string;
    help_text?: string;
    show_on_scholenwijzer?: boolean;
}

export enum FilterOperator {
    AND = 'AND',
    OR = 'OR'
}

export interface Filter {
    name: string;
    explanation?: string;
    options: Option[];
    field: string;
    operator?: FilterOperator;
}

export interface LocationInfo extends Location {
    distance?: number; // Distance in meters
}

export interface LocationsResponse {
    meta: Filter[];
    locations: LocationInfo[];
}

export interface ToggleOption extends Option {
    active: boolean;
}

export interface FilterState {
    active: boolean;
    info: Filter;
    options: ToggleOption[];
}

enum SortOption {
    NAME = 'name',
    DISTANCE = 'distance',
}

declare const $;

function hasOneOfActiveOptions(value: number | null | number[], options: ToggleOption[], filterOperator: FilterOperator | undefined): boolean {
    const activeOptions = options.filter(o => o.active);
    // Nothing is selected, so no filter is necessary
    if (activeOptions.length === 0) {
        return true;
    }
    const operator = filterOperator ? filterOperator : FilterOperator.AND;

    if (Array.isArray(value)) {
        switch (operator) {
            case FilterOperator.AND:
                return activeOptions.every(option => value.includes(option.id as number));
            case FilterOperator.OR:
                return activeOptions.some(option => value.includes(option.id as number));
            default:
                throw new Error('No operator');
        }
    }

    return activeOptions.some(option => value === option.id);
}

export class DistanceFormatter implements NouiFormatter {
    to(value: number): string {
        if (value === MAX_DISTANCE) {
            return '';
        }

        return value + ' km';
    }

    from(value: string): number {
        return 0;
    }
}

const MAX_DISTANCE = 10;

@Component({
    selector: 'app-map',
    templateUrl: './map.component.html',
    styleUrls: ['./map.component.scss']
})
export class MapComponent implements OnInit, OnDestroy {
    locations: LocationInfo[];
    visibleLocations: LocationInfo[];
    filters: FilterState[];
    map: google.maps.Map | null = null;
    markersByLocationId: { [key: number]: google.maps.Marker } = {};
    private infowindow: google.maps.InfoWindow;
    subscriptions: Subscription[] = [];
    sorting = SortOption.NAME;
    SortOption = SortOption;
    zipcodeLocation: number[] = [];
    categoryType: CategoryType;
    currentHover: LocationInfo | null = null;
    Math = Math;
    activeMarker: google.maps.Marker | null = null;
    showMap = false;
    screenWidth = window.innerWidth;
    mobile = false;

    @ViewChild(NouisliderComponent) noUiSlider;
    sliderConfig = {
        start: MAX_DISTANCE,
        padding: [0.5, 0],
        connect: [true, false],
        range: {
            min: 0,
            max: MAX_DISTANCE
        },
        pips: {
            mode: 'positions',
            values: [0, 25, 50, 75, 100],
            density: 5,
            format: {
                to: (value) => {
                    return value !== MAX_DISTANCE ? value.toString().replace('.', ',') : 'alles';
                }
            }
        },
        step: 0.5
    };
    distanceSlideEvent$ = new Subject();
    sliderTooltips = false;
    distanceFilterOpen = false;
    distanceFormatter = new DistanceFormatter();

    form = new UntypedFormGroup(
        {
            zipcode: new UntypedFormControl('', [Validators.pattern(/^[1-9][0-9]{3} ?(?!sa|sd|ss)[a-z]{2}$/i)]),
            distance: new UntypedFormControl(MAX_DISTANCE, []),
        }
    );
    infoWindowLocation: Location;
    homeMarker: google.maps.Marker | null = null;
    static managesOwnAnalytics = true as const;

    constructor(
        private route: ActivatedRoute,
        private locationService: LocationService,
        private categoryService: CategoryService,
        private http: HttpClient,
        private router: Router,
        private routerHelper: RouterHelperService,
        private decimalPipe: DecimalPipe,
        private zipcodeService: ZipcodeService,
        private seo: SeoService,
        private mapService: MapService,
        private favoriteService: FavouriteService,
        private googleAnalyticsService: GoogleAnalyticsService
    ) {
    }

    ngOnDestroy(): void {
        this.subscriptions.forEach(s => s.unsubscribe());
    }

    ngOnInit(): void {
        this.screenWidth = window.innerWidth;
        this.mobile = window.innerWidth < 880;

        this.seo.updateTags(
            'Scholenwijzer Den Haag',
            'De Scholenwijzer geeft ouders en kinderen informatie over alle verschillende scholen in Den Haag en omstreken. Dat maakt het kiezen van een school een stuk makkelijker! En er is nog veel meer uit de Scholenwijzer te halen. Hoe weet u welke school het beste past bij u en uw kind? Waar moet u op letten? Hoe werkt het aanmelden? De Scholenwijzer geeft antwoord op al deze vragen en meer.'
        );

        if (this.route.snapshot.queryParams.zipcode) {
            this.zipcodeService.zipcode$.next(this.route.snapshot.queryParams.zipcode);
            this.form.controls['zipcode'].setValue(this.route.snapshot.queryParams.zipcode);
        }
        if (this.route.snapshot.queryParams.distance) {
            this.form.controls['distance'].setValue(parseFloat(this.route.snapshot.queryParams.distance));
        }

        this.updateFilterAndLocationHeights();

        this.categoryType = this.route.snapshot.params.category;

        const position$ = merge(of(this.form.controls['zipcode'].value), this.form.controls['zipcode'].valueChanges).pipe(
            distinctUntilChanged(),
            switchMap(zipcode => {
                if (this.form.controls['zipcode'].valid) {
                    this.zipcodeService.zipcode$.next(zipcode);
                }

                if (!zipcode || !this.form.controls['zipcode'].valid) {
                    // If no zipcode is provided we no longer are able to filter / order based on distance
                    if (this.locations) {
                        this.locations.forEach(location => {
                            delete location.distance; // todo: side-effect
                        });
                    }
                    this.zipcodeLocation = []; // todo: side-effect
                    this.sorting = SortOption.NAME; // todo: side-effect

                    return EMPTY;
                }

                return from(this.mapService.findLatLang(zipcode)).pipe(
                    catchError(e => {
                        // TODO: Show toastr
                        alert('Kon geen locatie vinden bij opgegeven postcode. Controleer of deze correct is.');

                        return EMPTY;
                    })
                );
            })
        );

        this.subscriptions.push(
            // btn-favorite-manual
            fromEvent(document, 'click').subscribe(event => {
                // @ts-expect-error event.target should have className
                if (event.target.className.match(/\bbtn-favorite-manual\b/)) {
                    event.preventDefault();
                    event.stopPropagation();

                    this.favoriteService.toggleFavorite(this.infoWindowLocation.id, this.infoWindowLocation.location_type);

                    const isFavorite = this.favoriteService.isFavorite(this.infoWindowLocation.id);
                    let subtitle = '';
                    if (this.infoWindowLocation.education_field){
                        subtitle = this.locationService.getDisplayValue(this.infoWindowLocation.education_field, this.locationService.getOptionsForField(this.filters.map(f => f.info), 'education_field'));
                    }
                    this.infowindow.setContent(this.mapService.getInfoDialogHtml(this.infoWindowLocation, subtitle, isFavorite));
                }

                // @ts-expect-error event.target should have className
                if (event.target.className.match(/\bgo-to-link\b/)) {
                    event.preventDefault();
                    event.stopPropagation();

                    // @ts-expect-error event.target should have target
                    this.routerHelper.open(event.target.href);
                }

                // @ts-expect-error event.target should have className
                if (event.target.className === 'popup-close') {
                    this.infowindow.close();
                    if (this.activeMarker) {
                        this.activeMarker.setIcon(this.getMarkerIcon(false));
                    }
                    event.preventDefault();
                    event.stopPropagation();
                }
            }),
            this.route.params.pipe(
                switchMap(params => {
                    return this.http.get(environment.api + '/locations/' + params.category);
                }),
            ).subscribe((locationsResponse: LocationsResponse) => {
                this.googleAnalyticsService.sendAnalytics();

                const urlParams = this.route.snapshot.queryParams;

                this.locations = locationsResponse.locations;
                this.locations.filter(location => location.location_type === LocationType.KO).forEach(koLocation => {
                    const rand = Math.round(1 + Math.random() * 9);
                    koLocation.image = `/assets/img/photos/KO/Scholenwijzer_KO_Thumbs_${rand.toString().padStart(2, '0')}.jpg`;
                });

                this.filters = locationsResponse.meta.map(filterInfo => {
                    const activeOptions = filterInfo.field in urlParams ? urlParams[filterInfo.field].split(',').map(param => parseInt(param, undefined)) : [];

                    const usableFilters = filterInfo.options
                        .filter(filterOption => filterOption.show_on_scholenwijzer !== false)
                        .filter(filterOption => { // Filters out options which never match locations
                            const locationsCount = this.locations.filter(location => {
                                const locationValue = location[filterInfo.field];

                                if (Array.isArray(locationValue)) {
                                    return locationValue.includes(filterOption.id);
                                }

                                return locationValue === filterOption.id;
                            }).length;

                            return locationsCount > 0;
                        });

                    return {
                        active: false,
                        options: usableFilters.map(option => {
                            return {
                                ...option,
                                active: activeOptions.includes(option.id)
                            } as ToggleOption;
                        }),
                        info: filterInfo
                    } as FilterState;
                });

                this.jeromeStuff();
            }),

            fromEvent(notNull(document.getElementById('gmap')), 'click').pipe(
                filter(e => {
                    return notNull(e.target && (e.target as HTMLElement).tagName === 'A' && (e.target as HTMLElement).classList.contains('go-to-location'));
                })
            ).subscribe(e => {
                e.preventDefault();
                e.stopPropagation();

                this.routerHelper.open((e.target as HTMLAnchorElement).href);
            }),

            this.form.valueChanges.pipe(
                debounceTime(300)
            ).subscribe(result => {
                if (this.form.valid) {
                    this.updateVisibleLocations();
                    this.updateUrl();
                }
            }),

            position$.subscribe(position => {
                this.zipcodeLocation = position;
                this.drawHomeMarker();
                this.sorting = SortOption.DISTANCE;

                this.updateLocationDistances();
                this.updateVisibleLocations();
                this.updateUrl();
            })
        );
    }

    sortVisibleLocations(): void {
        if (this.sorting === SortOption.DISTANCE) {
            this.visibleLocations.sort((a, b) => {
                if (!a.distance || !b.distance) {
                    return 0;
                }

                if (a.distance < b.distance) {
                    return -1;
                }
                if (a.distance > b.distance) {
                    return 1;
                }
                return 0;
            });
        }
    }

    updateVisibleLocations(): void {
        if (!this.locations) {
            return;
        }

        this.visibleLocations = this.locations.filter(l => {
            for (let i = 0; i !== this.filters.length; i++) {
                const f = this.filters[i];
                const value = l[f.info.field];

                if (!hasOneOfActiveOptions(value, f.options, f.info.operator)) {
                    return false;
                }
            }
            return true;
        });

        const distance = this.form.controls['distance'].value;
        if (distance !== MAX_DISTANCE) {
            this.visibleLocations = this.visibleLocations.filter(l => {
                if (l.distance && l.distance > (distance * 1000)) {
                    return false;
                }
                return true;
            });
        }

        this.sortVisibleLocations();

        const visibleLocationIds = this.visibleLocations.map(l => l.id);
        Object.entries(this.markersByLocationId).forEach(
            ([key, value]) => {
                if (visibleLocationIds.indexOf(parseInt(key, undefined)) === -1) {
                    value.setMap(null);
                    delete this.markersByLocationId[key];
                }
            }
        );

        this.visibleLocations.filter(l => !(l.id in this.markersByLocationId)).forEach(l => {
            this.addMarkerForLocation(l);
        });

        this.updateFilterAndLocationHeights();
    }

    getMarkerIcon(active = true) {
        return {
            url: 'assets/img/map/' + (active ? 'marker-active.png' : 'marker.png'), // url
            scaledSize: new google.maps.Size(29, 43), // scaled size
            origin: new google.maps.Point(0, 0), // origin
            anchor: new google.maps.Point(15, 43) // anchor
        };
    }

    addMarkerForLocation(l: Location): void {
        const icon = this.getMarkerIcon(l.id === this.currentHover?.id);

        const marker = new google.maps.Marker({
            position: new google.maps.LatLng(l.latitude, l.longitude),
            map: this.map,
            icon,
            zIndex: (l.id === this.currentHover?.id ? 1 : 0)
        });

        this.markersByLocationId[l.id] = marker;

        google.maps.event.addListener(marker, 'click', () => {
            if (this.activeMarker) {
                this.activeMarker.setIcon(icon);
            }
            this.activeMarker = marker;
            marker.setIcon(this.getMarkerIcon(true));

            this.infowindow.close();
            this.infoWindowLocation = l;

            let subtitle = '';
            if (l.education_field) {
              subtitle = this.locationService.getDisplayValue(l.education_field, this.locationService.getOptionsForField(this.filters.map(f => f.info), 'education_field'));
            }

            this.infowindow.setContent(this.mapService.getInfoDialogHtml(l, subtitle));
            this.infowindow.open(notNull(this.map), marker);
        });

    }


    getLocationLink(l: Location): string|null {
        return this.locationService.getLocationLink(l);
    }

    onMapInit(): void {
        this.infowindow = new google.maps.InfoWindow();
        this.updateLocationDistances();
        this.updateVisibleLocations();
        this.drawHomeMarker();
        this.updateUrl();
    }

    jeromeStuff() {
        $(document).ready(() => {
            $('.infotip').tooltipster({
                maxWidth: 320,
                trigger: 'click',
                contentAsHTML: true,
                interactive: true,
            });

            $('.filter-layer-toggle').on('click', (e) => {
                const $filterbox = $('.filter-layer');

                if ($filterbox.hasClass('open')) {
                    $filterbox.removeClass('open');
                    $('body').removeClass('no-scroll show-filters');
                } else {
                    $filterbox.addClass('open');
                    $('body').addClass('no-scroll show-filters');
                }
                e.preventDefault();

            });

            $('.toggle-map').on('click', function(e) {

                if ($('.section-finder').hasClass('fullscreen')) {
                    $('.section-finder').removeClass('fullscreen');
                } else {
                    $('.section-finder').addClass('fullscreen');
                }

                e.preventDefault();

            });

            if ($('#gmap').length) {
                const centerpoint = '52.070499, 4.300700';
                const center = centerpoint.split(',');

                const mapcolors = [{featureType: 'landscape', elementType: 'all', stylers: [{visibility: 'on'}]}, {
                    featureType: 'landscape',
                    elementType: 'geometry.fill',
                    stylers: [{color: '#f2f4f2'}]
                }, {featureType: 'poi.attraction', elementType: 'all', stylers: [{visibility: 'off'}]}, {
                    featureType: 'poi.attraction',
                    elementType: 'geometry',
                    stylers: [{visibility: 'off'}]
                }, {featureType: 'poi.business', elementType: 'all', stylers: [{visibility: 'off'}]}, {
                    featureType: 'poi.government',
                    elementType: 'all',
                    stylers: [{visibility: 'off'}]
                }, {featureType: 'poi.medical', elementType: 'all', stylers: [{visibility: 'off'}]}, {
                    featureType: 'poi.park',
                    elementType: 'geometry.fill',
                    stylers: [{color: '#98e2a1'}]
                }, {
                    featureType: 'poi.place_of_worship',
                    elementType: 'all',
                    stylers: [{visibility: 'off'}]
                }, {
                    featureType: 'poi.place_of_worship',
                    elementType: 'labels.icon',
                    stylers: [{color: '#7d2e80'}, {visibility: 'on'}]
                }, {featureType: 'poi.school', elementType: 'all', stylers: [{visibility: 'off'}]}, {
                    featureType: 'poi.school',
                    elementType: 'labels.icon',
                    stylers: [{color: '#f28700'}, {visibility: 'off'}]
                }, {
                    featureType: 'poi.sports_complex',
                    elementType: 'all',
                    stylers: [{visibility: 'on'}]
                }, {
                    featureType: 'poi.sports_complex',
                    elementType: 'geometry.fill',
                    stylers: [{color: '#98e2a1'}]
                }, {featureType: 'poi.sports_complex', elementType: 'labels', stylers: [{visibility: 'off'}]}, {
                    featureType: 'transit',
                    elementType: 'all',
                    stylers: [{visibility: 'on'}]
                }, {featureType: 'transit', elementType: 'labels.icon', stylers: [{color: '#69a8de'}]}, {
                    featureType: 'water',
                    elementType: 'all',
                    stylers: [{color: '#69a8de'}]
                }];

                const mapElement = document.getElementById('gmap');
                if (mapElement !== null) {
                    this.map = new google.maps.Map(mapElement, {
                        zoom: 12,
                        // @ts-expect-error center[0] and center[1] should work
                        center: new google.maps.LatLng(center[0], center[1]),
                        mapTypeId: 'terrain',
                        scrollwheel: false,
                        mapTypeControl: false,
                        panControl: false,
                        fullscreenControl: false
                    });
                }

                if (this.map) {
                    this.map.setOptions({styles: mapcolors});
                }

                google.maps.event.addDomListener(window, 'resize', () => {
                    if (this.map) {
                        google.maps.event.trigger(this.map, 'resize');
                        const center = this.map.getCenter();
                        if (center) {
                            this.map.setCenter(center);
                        }
                    }
                });

                if (this.map) {
                    google.maps.event.addListener(this.map, 'click', () => {
                        if (!this.activeMarker) {
                            return;
                        }
                        this.activeMarker.setIcon(this.getMarkerIcon(false));
                        this.infowindow.close();
                    });
                }


                this.onMapInit();
            }
        });
    }


    toggleOpenFilter(f: FilterState, event: Event): void {
        event.preventDefault();
        event.stopPropagation();
        f.active = !f.active;

        this.updateFilterAndLocationHeights();
    }

    toggleFilter(o: ToggleOption, $event: MouseEvent): void {
        $event.stopPropagation();
        $event.preventDefault();
        o.active = !o.active;
        this.updateVisibleLocations();
        this.updateUrl();

        this.updateFilterAndLocationHeights();
    }

    updateUrl(): void {
        if (!this.filters) {
            return;
        }


        const queryParams = {};
        this.filters.forEach(f => {
            const options = f.options.filter(o => o.active);
            if (options.length === 0) {
                return;
            }
            queryParams[f.info.field] = options.map(o => o.id).join(',');
        });

        if (this.zipcodeService.zipcode$.getValue()) {
            queryParams['zipcode'] = this.zipcodeService.zipcode$.getValue();
        }

        if (this.form.controls['distance'].value !== MAX_DISTANCE) {
            queryParams['distance'] = this.form.controls['distance'].value;
        }

        this.router.navigate(
            [],
            {
                relativeTo: this.route,
                queryParams
            });
    }

    trackById(loc: LocationInfo): number {
        return loc.id;
    }

    onSortChange(): void {
        this.updateVisibleLocations();
    }

    activeFilters(options: ToggleOption[]): ToggleOption[] {
        return options.filter(o => o.active);
    }

    getAvailableLocations(f2: Filter, o: ToggleOption): number {
        let locations = this.locations;

        const distance = this.form.controls['distance'].value;
        if (distance !== MAX_DISTANCE) {
            locations = locations.filter(l => {
                if (l.distance && l.distance > (distance * 1000)) {
                    return false;
                }
                return true;
            });
        }

        return locations.filter(l => {
            for (let i = 0; i !== this.filters.length; i++) {
                const f = this.filters[i];
                const value = l[f.info.field];

                const modifiedOptions: ToggleOption[] = f.options.map(option => {
                    if (f2.field === f.info.field) {
                        let active;
                        if (Array.isArray(value)) {
                            // In case the value is an array, keep all other options for this filter in account and only change option for this option
                            active = option.id === o.id ? true : (f2.operator === FilterOperator.OR ? false : option.active);
                        } else {
                            // In the case the value is string show the amount of items has this regardless of other values in this filter
                            active = option.id === o.id;
                        }

                        return {
                            ...option,
                            active
                        };
                    }
                    return option;
                });

                if (!hasOneOfActiveOptions(value, modifiedOptions, f.info.operator)) {
                    return false;
                }
            }
            return true;
        }).length;
    }

    hoverEnterLocation(loc: LocationInfo): void {
        this.currentHover = loc;

        const existingMarker = this.markersByLocationId[loc.id];
        if (existingMarker) {
            existingMarker.setMap(null);
            delete this.markersByLocationId[loc.id];
            this.addMarkerForLocation(loc);
        }
    }

    hoverLeaveLocation(loc: LocationInfo): void {
        if (this.currentHover?.id === loc.id) {
            this.currentHover = null;

            const existingMarker = this.markersByLocationId[loc.id];
            if (existingMarker) {
                existingMarker.setMap(null);
                delete this.markersByLocationId[loc.id];
                this.addMarkerForLocation(loc);
            }
        }
    }

    displayDistance(): string {
        const value = this.distanceFormatter.to(this.form.controls['distance'].value);
        if (value === 'Alles') {
            return '';
        } else {
            return value;
        }
    }

    updateLocationDistances(): void {
        const position = this.zipcodeLocation;
        if (!position || !this.locations) {
            return;
        }

        const origin = new google.maps.LatLng(position[0], position[1]);
        this.locations.forEach(l => {
            // tslint:disable-next-line:max-line-length
            l.distance = google.maps.geometry.spherical.computeDistanceBetween(origin, (new google.maps.LatLng(l.latitude, l.longitude)));
        });
    }

    getDistanceDisplay(loc: LocationInfo): string | undefined {
        if (!loc.distance) {
            return;
        }
        const distance = loc.distance;

        if ([LocationType.KO, LocationType.PO, LocationType.SO].indexOf(loc.location_type) !== -1) {
            // 5 kph == 83.33 m/min
            return this.decimalPipe.transform(distance / 83, '1.0-0') + ' minuten lopen';
        }

        if ([LocationType.VO, LocationType.MBO].indexOf(loc.location_type) !== -1) {
            // 16 kph == 266,667m/min
            return this.decimalPipe.transform(distance / 267, '1.0-0') + ' minuten fietsen';
        }

        return this.decimalPipe.transform(distance / 1000, '1.1-1') + ' KM';
    }

    getDescriptionForType(type: CategoryType): string {
        return this.categoryService.getCategoryName(type);
    }

    resetFilters(event: Event): void {
        this.filters.forEach(f => {
            f.options.forEach(o => o.active = false);
        });
        this.form.reset({
            zipcode: '',
            distance: MAX_DISTANCE
        });

        this.updateVisibleLocations();
        this.updateUrl();

        event.stopPropagation();
        event.preventDefault();
    }

    drawHomeMarker(): void {
        if (!this.zipcodeLocation) {
            return;
        }

        // reset map
        if (this.homeMarker) {
            this.homeMarker.setMap(null);
            this.homeMarker = null;
        }

        this.homeMarker = new google.maps.Marker({
            position: new google.maps.LatLng(this.zipcodeLocation[0], this.zipcodeLocation[1]),
            map: this.map,
            icon: {
                url: 'assets/img/map/house.png',
                scaledSize: new google.maps.Size(45, 45),
                origin: new google.maps.Point(0, 0),
                anchor: new google.maps.Point(15, 43)
            },
            zIndex: 100
        });
        this.zoomInOnHome();
    }

    zoomInOnHome(): void {
        if (this.map && this.zipcodeLocation.length > 0) {
            const targetLatLng = new google.maps.LatLng(this.zipcodeLocation[0], this.zipcodeLocation[1]);
            this.map.panTo(targetLatLng);

            setTimeout(() => {
                if (this.map) {
                    this.map.setZoom(15);
                }
            }, 1000);
        }
    }

    updateFilterAndLocationHeights(): void {
        setTimeout(() => {
            $('.js-mh').matchHeight();
        }, 0);
    }

    @HostListener('window:resize', ['$event'])
    onResize(event: Event): void {
        this.screenWidth = window.innerWidth;
        this.mobile = window.innerWidth < 880;
    }
}

export { LocationType };

