import {Component, OnInit} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {HttpClient} from '@angular/common/http';
import {switchMap, take, tap} from 'rxjs/operators';
import {environment} from '../../../environments/environment';
import {updateLinks} from '../../shared/Utils';
import {SeoService} from '../../shared/services/seo.service';
import {SchoolChoiceLanguage} from '../../school-choice/school-choice.component';
import {GoogleAnalyticsService} from "../../shared/services/google-analytics.service";

interface Category {
  name: string;
  image: string;
  description: string;
  parent_category: {
    name: string;
  };
}

interface Article {
  id: number;
  slug: string;
  title: string;
  image: string;
}

@Component({
  selector: 'app-article-list',
  templateUrl: './article-list.component.html',
  styleUrls: ['./article-list.component.scss']
})
export class ArticleListComponent implements OnInit {

  category: Category | undefined;
  articles: Article[] = [];
  showFlags = false;
  SchoolChoiceLanguage = SchoolChoiceLanguage;
  routeLink = 'schoolkeuze-2-jarige/';
  static managesOwnAnalytics = true as const;

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private http: HttpClient,
    private seo: SeoService,
    private googleAnalyticsService: GoogleAnalyticsService) {
  }

  ngOnInit(): void {
    this.route.params.pipe(
      tap(params => {
        if (params.slug === 'aanmelden-bij-de-basisschool') {
          this.routeLink = 'schoolkeuze-3-jarige/';
        }

        if (params.slug === 'aanmelden-als-zij-instromer') {
          this.routeLink = 'schoolkeuze-4-jarige/';
        }

        if (params.slug === 'aanmelden-bij-de-basisschool' || params.slug === 'een-basisschool-kiezen' || params.slug === 'aanmelden-als-zij-instromer') {
          this.showFlags = true;
        }
      }),
      switchMap(params => this.http.get<{ category: Category, articles: Article[] }>(environment.api + `/category/${params.slug}`)),
      take(1)
    ).subscribe(getResult => {
        if (getResult.articles.length !== 0) {
            console.log(getResult.articles.length);
            this.googleAnalyticsService.sendAnalytics();
        }
      this.category = {
        ...getResult.category,
        image: getResult.category.image ?? '/assets/img/unavailable.jpg'
      };

      this.seo.updateTags(
          this.category.name,
          this.category.description,
          this.category.image ?? '/assets/img/unavailable.jpg'
      );

      this.articles = getResult.articles.map(article => {
        return {
          ...article,
          image: article.image ?? '/assets/img/unavailable.jpg'
        };
      });

      if (this.articles.length === 1) {
        setTimeout(() => this.router.navigate(['/', 'artikel', this.articles[0].slug], {replaceUrl: true}), 0);
      }
    });

    setTimeout(() => {
      // @ts-expect-error jerome stuff
        $('.js-mh').matchHeight();
      updateLinks();
    }, 200);
  }

  scrollTo(id: string, event: Event): void {
    event.preventDefault();
    event.stopPropagation();

    const scrollTarget = document.getElementById(id);
    if (scrollTarget) {
      scrollTarget.scrollIntoView({
        behavior: 'smooth',
        block: 'center'
      });
    }
  }
}
