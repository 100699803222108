import {Component, OnInit} from '@angular/core';
import {map, switchMap} from "rxjs/operators";
import {ActivatedRoute, UrlSegment} from "@angular/router";
import {TranslateService} from "@ngx-translate/core";
import {GoogleAnalyticsService} from "../shared/services/google-analytics.service";

enum LanguageChoice {
    DUTCH = 'nl',
    ENGLISH = 'en',
    UKRAINIAN = 'uk',
    BULGARIAN = 'bu',
    TURKISH = 'tu',
    POLISH = 'po',
    ARABIC = 'ar',
}

@Component({
  selector: 'app-new-in-the-netherlands',
  templateUrl: './new-in-the-netherlands.component.html',
  styleUrls: ['./new-in-the-netherlands.component.scss']
})
export class NewInTheNetherlandsComponent implements OnInit {
    selectedLanguage = LanguageChoice.DUTCH;
    LanguageChoice = LanguageChoice
    static managesOwnAnalytics = true as const;

    constructor(private route: ActivatedRoute,
                private translate: TranslateService,
                private googleAnalyticsService: GoogleAnalyticsService) {
    }

    ngOnInit(): void {
        this.route.url.pipe(
            map((url: UrlSegment[]) => url[1].path),
            switchMap((language: string) => {
                if (Object.values(LanguageChoice).includes(language as LanguageChoice)) {
                    this.googleAnalyticsService.sendAnalytics();
                }

                this.selectedLanguage = language as LanguageChoice;
                return this.translate.use(language);
            }),
        ).subscribe();
    }

}
