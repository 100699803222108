import {Component, OnDestroy, OnInit} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {environment} from '../../../environments/environment';
import {ActivatedRoute} from '@angular/router';
import {map, switchMap, tap} from 'rxjs/operators';
import {MapService} from '../../shared/services/map.service';
import {Subscription} from 'rxjs';
import {updateLinks} from '../../shared/Utils';
import {GoogleAnalyticsService} from "../../shared/services/google-analytics.service";

interface KoLocation {
    id: number;
    name: string;
    image: string;
    website: string|null;
    phone: string;
    email: string;
    street: string;
    house_number: number;
    house_number_addition: string;
    zipcode: string;
    place: string;
    latitude: number|null;
    longitude: number|null;
}

@Component({
    selector: 'app-ko-location',
    templateUrl: './ko-location.component.html',
    styleUrls: ['./ko-location.component.scss']
})
export class KoLocationComponent implements OnInit, OnDestroy {
    location: KoLocation | undefined;
    static managesOwnAnalytics = true as const;

    subs = new Subscription();

    constructor(
        private route: ActivatedRoute,
        private http: HttpClient,
        private mapService: MapService,
        private googleAnalyticsService: GoogleAnalyticsService) {
    }

    ngOnInit(): void {
        const paramsSub = this.route.params.pipe(
            tap(() => window.scrollTo({top: 0})),
            switchMap(params => this.http.get<KoLocation>(environment.api + `/location/${params.id}`)),
            map(location => {
                let website: string|null = null;
                if (location.website) {
                    if (location.website.startsWith('http')) {
                        website = location.website;
                    } else {
                        website = 'http://' + location.website;
                    }
                }


                return {
                    ...location,
                    website
                };
            })
        ).subscribe(location => {
            this.googleAnalyticsService.sendAnalytics();
            this.location = location;

            setTimeout(() => {
                // @ts-expect-error jerome stuff
                $('.js-mh').matchHeight();
                this.mapService.initMap(location.latitude, location.longitude);
                updateLinks();
            }, 0);
        });

        this.subs.add(paramsSub);

        // @ts-expect-error jerome stuff
        initJeromeJs();
    }

    scroll(el: HTMLElement, e: Event): void {
        el.scrollIntoView({
            behavior: 'smooth',
            block: 'center'
        });
        e.preventDefault();
        e.stopPropagation();
    }

    ngOnDestroy(): void {
        this.subs.unsubscribe();
    }
}
