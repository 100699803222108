import {Component, HostListener, OnDestroy, OnInit} from '@angular/core';
import {map, switchMap, tap} from 'rxjs/operators';
import {environment} from '../../../environments/environment';
import {ActivatedRoute, Router} from '@angular/router';
import {HttpClient} from '@angular/common/http';
import {MapService} from '../../shared/services/map.service';
import {PoYearCapacity} from './po-year-capacity-table/po-year-capacity-table.component';
import {Subscription} from 'rxjs';
import {updateLinks} from '../../shared/Utils';
import {SeoService} from '../../shared/services/seo.service';
import {AnimationOptions} from "ngx-lottie";
import lottie, {AnimationItem} from 'lottie-web';
import {GoogleAnalyticsService} from "../../shared/services/google-analytics.service";

export interface SignupInfo {
    procedure_participation: boolean;
    max_seats: number;
    has_ceiling: boolean;
    year: number;
    precedence_rule_1: string;
    precedence_rule_1_explanation: string;
    precedence_rule_2: string;
    precedence_rule_2_explanation: string;
    precedence_rule_3: string;
    precedence_rule_3_explanation: string;
    precedence_rule_4: string;
    precedence_rule_4_explanation: string;
}

interface PoLocation {
    id: number;
    name: string;
    image: string;
    website: string | null;
    phone: string;
    email: string;
    street: string;
    house_number: number;
    house_number_addition: string;
    zipcode: string;
    place: string;

    brin_id: string;

    education_field: string;
    pupil_count: number;
    signup_info?: SignupInfo;
    year_capacity?: PoYearCapacity;
    last_year_capacity?: PoYearCapacity;
    about_us: string;
    our_education: string;
    school_times: string;
    become_acquainted: string;

    properties: { name: string, help_text?: string }[];

    latitude: number | null;
    longitude: number | null;
}

@Component({
    selector: 'app-po-location',
    templateUrl: './po-location.component.html',
    styleUrls: ['./po-location.component.scss']
})
export class PoLocationComponent implements OnInit, OnDestroy {
    location: PoLocation | undefined;
    subs = new Subscription();
    randInt = Math.floor(Math.random() * 7) + 1;

    lottieOptions: AnimationOptions = {};
    private animation: AnimationItem;
    static managesOwnAnalytics = true as const;

    constructor(private route: ActivatedRoute,
                private http: HttpClient,
                private mapService: MapService,
                private router: Router,
                private seo: SeoService,
                private googleAnalyticsService: GoogleAnalyticsService) {}

    goBack(event): void {
        event.preventDefault();
        event.stopPropagation();

        const {navigationId} = window.history.state;
        if (navigationId > 1) {
            window.history.back();
        } else {
            this.router.navigateByUrl('/categorie/po/map');
        }
    }

    ngOnInit(): void {
        const paramsSub = this.route.params.pipe(
            tap(() => window.scrollTo({top: 0})),
            switchMap(params => this.http.get<PoLocation>(environment.api + `/po/${params.id}`)),
            map(location => {
                let website: string | null = null;
                if (location.website) {
                    if (location.website.startsWith('http')) {
                        website = location.website;
                    } else {
                        website = 'http://' + location.website;
                    }
                }


                return {
                    ...location,
                    website
                };
            })
        ).subscribe(location => {
            this.googleAnalyticsService.sendAnalytics();

            this.location = location;

            this.seo.updateTags(
                location.name,
                location.about_us,
                location.image
            );

            setTimeout(() => {
                // @ts-expect-error jerome stuff
                $('.js-mh').matchHeight();

                // @ts-expect-error jerome stuff
                $('.infotip').tooltipster({
                    maxWidth: 320,
                    trigger: 'click'
                });

                this.mapService.initMap(location.latitude, location.longitude);

                updateLinks();

                this.animation = lottie.loadAnimation({
                    container: document.querySelector('ng-lottie') as Element,
                    renderer: 'svg',
                    loop: false,
                    autoplay: false,
                    path: '/assets/img/svg/school/bo/scroll.json'
                });
            }, 0);
        });

        this.subs.add(paramsSub);


        // @ts-expect-error jerome stuff
        initJeromeJs();
    }

    @HostListener('window:scroll', ['$event'])
    onScroll(event: Event): void {
        const scrollTop = window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop || 0;
        const docHeight = document.documentElement.scrollHeight - document.documentElement.clientHeight;
        const scrollPercent = scrollTop / docHeight;

        const frame = Math.min(Math.max(scrollPercent * 60 * 10, 0), 60 - 1);
        if (this.animation) {
            this.animation.goToAndStop(frame, true);
        }
    }

    precedenceRulesArray(): { rule: string, explanation?: string }[] {
        const rules: { rule: string, explanation: string }[] = [];

        if (this.location?.signup_info) {
            for (let i = 0, a = i + 1; i < 4; i++, a++) {
                if (this.location.signup_info['precedence_rule_' + a]) {
                    rules.push({
                        rule: this.location.signup_info[`precedence_rule_${a}`],
                        explanation: this.location.signup_info[`precedence_rule_${a}_explanation`]
                    });
                }
            }
        }

        return rules;
    }

    scroll(el: HTMLElement, e: Event): void {
        el.scrollIntoView({
            behavior: 'smooth',
            block: 'center'
        });

        e.preventDefault();
        e.stopPropagation();
    }

    ngOnDestroy(): void {
        this.subs.unsubscribe();
    }
}
