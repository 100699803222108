import {Component, ElementRef, HostListener, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {HttpClient} from '@angular/common/http';
import {map, switchMap, tap} from 'rxjs/operators';
import {environment} from '../../../environments/environment';
import {MapService} from '../../shared/services/map.service';
import {combineLatest, Subscription} from 'rxjs';
import {updateLinks} from '../../shared/Utils';
import {SeoService} from '../../shared/services/seo.service';
import {DomSanitizer, SafeHtml} from '@angular/platform-browser';
import {AnimationOptions} from "ngx-lottie";
import lottie, {AnimationItem} from 'lottie-web';
import {GoogleAnalyticsService} from "../../shared/services/google-analytics.service";

enum OpenDayType {
    OPEN_DAY = 'OPEN_DAY',
    OPEN_EVENING = 'OPEN_EVENING',
    FOLLOW_ALONG_DAY = 'FOLLOW_ALONG_DAY',
    PARENTS_DAY = 'PARENTS_DAY',
    EDUCATION_EVENING_FOR_PARENTS_AND_CHILDREN = 'EDUCATION_EVENING_FOR_PARENTS_AND_CHILDREN',
    GUIDED_TOURS = 'GUIDED_TOURS',
    WORKSHOPS = 'WORKSHOPS',
}

interface OpenDayDisplay {
    title: string;
    color: string;
}

interface VoLocation {
    id: number;
    name: string;
    image: string;
    website: string | null;
    phone: string;
    email: string;
    street: string;
    house_number: number;
    house_number_addition: string;
    zipcode: string;
    place: string;

    brin_id: string;

    education_field: string;
    pupil_count: number;
    max_seats: number;

    about_us: string;
    education_concept: string;
    signup_description: string;
    brugperiode_description: string;
    more_information: string;
    precedence_rule_1: string;
    precedence_rule_2: string;
    precedence_rule_3: string;

    has_open_days: boolean;
    open_days: {
        id: number;
        start: string;
        end: string;
        description: string;
        open_day_type: OpenDayType;
        info_url: string | null;
        ics_file: string | null;
    }[];

    first_year_costs: {
        costs: number | null,
        explanation: string | null,
        category?: 'CATEGORY_2' | 'CATEGORY_3' | 'CATEGORY_4'
    }[];
    properties: {
        name,
        help_text: string | null
    }[];
    first_grade_capacities: {
        type: string,
        bsa: string,
        available_seats: number,
        percentage_of_total: number
    }[];

    education_types: {
        id: number,
        name: string,
        explanation: string | null
    }[];

    latitude: number | null;
    longitude: number | null;

    scholenopdekaart_link: string | null;

    period: number;
}

const CATEGORY_INFO: {[key in 'CATEGORY_2' | 'CATEGORY_3' | 'CATEGORY_4']: {name: string, extra?: string}} = {
    CATEGORY_2: {
        name: 'Schoolmaterialen'
    },
    CATEGORY_3: {
        name: 'Devices'
    },
    CATEGORY_4: {
        name: 'Extra-curriculaire activiteiten buiten verplichte onderwijsprogramma',
        extra: 'Scholen kunnen een vrijwillige ouderbijdrage vragen voor extra activiteiten. Als ouders deze bijdrage niet voldoen, dan mag de school een leerling niet uitsluiten van deelname en ook geen kostenloos alternatief bieden.'
    }
};

@Component({
    selector: 'app-vo-location',
    templateUrl: './vo-location.component.html',
    styleUrls: ['./vo-location.component.scss']
})
export class VoLocationComponent implements OnInit, OnDestroy {
    location: VoLocation | undefined;
    subs = new Subscription();
    randInt: number[] = this.getRandomNumbers();

    categoryInfo = CATEGORY_INFO;
    expenses: VoLocation['first_year_costs'] = [];
    @ViewChild('openDays') openDaysElement: ElementRef;

    openDayTypeDisplay: {[key in OpenDayType]: OpenDayDisplay} = {
        [OpenDayType.OPEN_DAY]: {title: 'Open dag', color: '#68A8DD'},
        [OpenDayType.OPEN_EVENING]: {title: 'Open avond', color: '#4FA058'},
        [OpenDayType.FOLLOW_ALONG_DAY]: {title: 'Meeloopdag', color: '#E02D16'},
        [OpenDayType.PARENTS_DAY]: {title: 'Ouderavond', color: '#7C2D7F'},
        [OpenDayType.EDUCATION_EVENING_FOR_PARENTS_AND_CHILDREN]: {title: 'Onderwijsavond voor ouders en kinderen', color: '#D82B84'},
        [OpenDayType.GUIDED_TOURS]: {title: 'Rondleidingen', color: '#F7D62D'},
        [OpenDayType.WORKSHOPS]: {title: 'Workshops', color: '#155429'}
    };

    lottieOptions: AnimationOptions = {};
    private animation: AnimationItem;
    static managesOwnAnalytics = true as const;

    constructor(private route: ActivatedRoute,
                private http: HttpClient,
                private mapService: MapService,
                private router: Router,
                private seo: SeoService,
                private sanitizer: DomSanitizer,
                private googleAnalyticsService: GoogleAnalyticsService) {}

    ngOnInit(): void {
        const routeSub = combineLatest([
            this.route.params.pipe(
                tap(() => window.scrollTo({top: 0})),
                switchMap(params => this.http.get<VoLocation>(environment.api + `/vo/${params.id}`)),
                map(location => {
                    let website: string | null = null;
                    if (location.website) {
                        if (location.website.startsWith('http')) {
                            website = location.website;
                        } else {
                            website = 'http://' + location.website;
                        }
                    }

                    return {
                        ...location,
                        website
                    };
                })
            ),
            this.route.queryParams
        ]).subscribe(([location, queryParams]) => {
            this.googleAnalyticsService.sendAnalytics();

            this.location = location;

            this.expenses = this.location.first_year_costs
                .filter(expense => expense.costs !== null && expense.costs !== undefined)
                .sort((a, b) => (a.category ?? '').localeCompare(b.category ?? ''));

            this.seo.updateTags(
                location.name,
                location.about_us,
                location.image
            );

            setTimeout(() => {
                // @ts-expect-error jerome stuff
                $('.js-mh').matchHeight();
                this.mapService.initMap(location.latitude, location.longitude);
                updateLinks();

                this.animation = lottie.loadAnimation({
                    container: document.querySelector('ng-lottie') as Element,
                    renderer: 'svg',
                    loop: false,
                    autoplay: false,
                    path: '/assets/img/svg/school/vo/scroll.json'
                });

                if (queryParams['scroll-action'] === 'open-days') {
                    this.scrollToOpenDays();
                }
            }, 0);
        });

        this.subs.add(routeSub);
    }

    @HostListener('window:scroll', ['$event'])
    onScroll(event: Event): void {
        const scrollTop = window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop || 0;
        const docHeight = document.documentElement.scrollHeight - document.documentElement.clientHeight;
        const scrollPercent = scrollTop / docHeight;

        const frame = Math.min(Math.max(scrollPercent * 60 * 10, 0), 60 - 1);
        if (this.animation) {
            this.animation.goToAndStop(frame, true);
        }
    }

    precedenceRulesArray(): { rule: string, explanation?: string }[] {
        const rules: { rule: string, explanation: string }[] = [];

        if (this.location) {
            for (let i = 0, a = i + 1; i < 3; i++, a++) {
                if (this.location['precedence_rule_' + a]) {
                    rules.push({
                        rule: this.location[`precedence_rule_${a}`],
                        explanation: this.location[`precedence_rule_${a}_explanation`]
                    });
                }
            }
        }

        return rules;
    }

    scroll(el: HTMLElement, e: Event): void {
        el.scrollIntoView({
            behavior: 'smooth',
            block: 'center'
        });
        e.preventDefault();
        e.stopPropagation();
    }

    scrollToOpenDays(): void {
        if (this.openDaysElement && this.openDaysElement.nativeElement) {
            this.openDaysElement.nativeElement.scrollIntoView({ behavior: 'smooth', block: 'end' });
        }
    }

    sumCapacity(): number | undefined {
        return this.location?.first_grade_capacities
            .map(item => item.available_seats)
            .reduce((a, b) => a + b, 0);
    }

    goBack(event): void {
        event.preventDefault();
        event.stopPropagation();

        const {navigationId} = window.history.state;
        if (navigationId > 1) {
            window.history.back();
        } else {
            this.router.navigateByUrl('/categorie/vo/map');
        }
    }

    getRandomNumbers(): number[] {
        const numbers: number[] = [];

        while (numbers.length < 2) {
            const randomNumber = Math.floor(Math.random() * 7) + 1;

            if (!numbers.includes(randomNumber)) {
                numbers.push(randomNumber);
            }
        }

        return numbers;
    }

    setOpenDayInnerHTML(description: string, openDayType: OpenDayType): SafeHtml {
        const openDayColor = this.openDayTypeDisplay[openDayType].color;

        const style = `
        --openDay-color: ${openDayColor};
    `;

        const html = `<div style="${style}">${description}</div>`;

        return this.sanitizer.bypassSecurityTrustHtml(html);
    }

    downloadIcsFile(icsString: string): void {
        const byteArray = Uint8Array.from(atob(icsString), char => char.charCodeAt(0));

        const icsContent = new TextDecoder().decode(byteArray);

        const blob = new Blob([icsContent], { type: 'text/calendar' });

        const url = window.URL.createObjectURL(blob);

        const a = document.createElement('a');
        a.href = url;
        a.download = 'event.ics';

        document.body.appendChild(a);
        a.click();
        document.body.removeChild(a);

        window.URL.revokeObjectURL(url);
    }


    ngOnDestroy(): void {
        this.subs.unsubscribe();
    }

    joinedEducationTypes(): string | null {
        return this.location?.education_types.map(type => type.name).join(', ') ?? null;
    }
}
