import {Component, OnInit} from '@angular/core';
import {ActivatedRoute, ActivationStart, NavigationEnd, Router} from '@angular/router';
import {filter, map} from 'rxjs/operators';
import {GoogleAnalyticsService} from "./shared/services/google-analytics.service";
import {NotFoundComponent} from "./not-found/not-found.component";

@Component({
    selector: 'app-root',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {
    title = 'website-scholenwijzer';
    activePath: string;

    constructor(private router: Router,
                private route: ActivatedRoute,
                private googleAnalyticsService: GoogleAnalyticsService) {
    }

    ngOnInit(): void {
        this.router.events.pipe(
            filter((event) => event instanceof NavigationEnd),
            map(() => this.rootRoute(this.route)),
            filter((route: ActivatedRoute) => route.outlet === 'primary'),
        ).subscribe((route: ActivatedRoute) => {
            if (route.component === NotFoundComponent) {
                return;
            }
            
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            if((route.component as any)?.managesOwnAnalytics) {
                return;
            }

            this.googleAnalyticsService.sendAnalytics();

        });

        this.router.events.subscribe((evt) => {
            if (!(evt instanceof NavigationEnd)) {
                return;
            }

            window.scrollTo(0, 0);
        });

        this.router.events.pipe(
            filter(e => e instanceof ActivationStart)
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
        ).subscribe((e: any) => {
            this.activePath = e.snapshot.routeConfig.path;
        });
    }
    private rootRoute(route: ActivatedRoute): ActivatedRoute {
        while (route.firstChild) {
            route = route.firstChild;
        }
        return route;
    }

}
