import {Component, HostListener, OnDestroy, OnInit} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {HttpClient} from '@angular/common/http';
import {map, switchMap, tap} from 'rxjs/operators';
import {environment} from '../../../environments/environment';
import {MapService} from '../../shared/services/map.service';
import {Subscription} from 'rxjs';
import {updateLinks} from '../../shared/Utils';
import {SeoService} from '../../shared/services/seo.service';
import lottie, {AnimationItem} from 'lottie-web';
import {AnimationOptions} from "ngx-lottie";
import {GoogleAnalyticsService} from "../../shared/services/google-analytics.service";

interface HboWoLocation {
    id: number;
    location_type: 'WO' | 'HBO';
    name: string;
    image: string;
    website: string;
    phone: string;
    email: string;
    street: string;
    house_number: number;
    house_number_addition: string;
    zipcode: string;
    place: string;

    school: {
        teaching: string,
        educations: string,
        special: string
    };

    latitude: number|null;
    longitude: number|null;
}

@Component({
    selector: 'app-hbo-wo-location',
    templateUrl: './hbo-wo-location.component.html',
    styleUrls: ['./hbo-wo-location.component.scss']
})
export class HboWoLocationComponent implements OnInit, OnDestroy {
    locationTypeNameMap: {[key in HboWoLocation['location_type']]: string} = {
        HBO: 'Hoger Beroepsonderwijs',
        WO: 'Universiteit'
    };
    randInt = Math.floor(Math.random() * 10) + 1;
    location: HboWoLocation | undefined;
    subs = new Subscription();

    lottieOptions: AnimationOptions = {};
    private animation: AnimationItem;
    static managesOwnAnalytics = true as const;

    constructor(
        private route: ActivatedRoute,
        private http: HttpClient,
        private mapService: MapService,
        private router: Router,
        private seo: SeoService,
        private googleAnalyticsService: GoogleAnalyticsService) {
    }

    ngOnInit(): void {
        const paramsSub = this.route.params.pipe(
            tap(() => window.scrollTo({top: 0})),
            switchMap(params => this.http.get<HboWoLocation>(environment.api + `/mbo/${params.id}`)),
            map(location => {
                return {
                    ...location,
                    website: location.website.startsWith('http') ? location.website : 'http://' + location.website
                };
            })
        ).subscribe(location => {
            this.googleAnalyticsService.sendAnalytics();
            this.location = location;

            this.seo.updateTags(
                location.name,
                location.school.teaching,
                location.image
            );

            setTimeout(() => {
                // @ts-expect-error jerome stuff
                $('.js-mh').matchHeight();
                this.mapService.initMap(location.latitude, location.longitude);

                updateLinks();

                this.animation = lottie.loadAnimation({
                    container: document.querySelector('ng-lottie') as Element,
                    renderer: 'svg',
                    loop: false,
                    autoplay: false,
                    path: '/assets/img/svg/school/hbo/scroll.json'
                });
            }, 0);
        });

        this.subs.add(paramsSub);
    }

    @HostListener('window:scroll', ['$event'])
    onScroll(event: Event): void {
        const scrollTop = window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop || 0;
        const docHeight = document.documentElement.scrollHeight - document.documentElement.clientHeight;
        const scrollPercent = scrollTop / docHeight;

        const frame = Math.min(Math.max(scrollPercent * 60 * 10, 0), 60 - 1);
        if (this.animation) {
            this.animation.goToAndStop(frame, true);
        }
    }

    scroll(el: HTMLElement, e: Event): void {
        el.scrollIntoView({
            behavior: 'smooth',
            block: 'center'
        });
        e.preventDefault();
        e.stopPropagation();
    }

    goBack(event): void {
        event.preventDefault();
        event.stopPropagation();

        const {navigationId} = window.history.state;
        if (navigationId > 1) {
            window.history.back();
        } else {
            this.router.navigateByUrl('/categorie/hbo-wo/map');
        }
    }

    ngOnDestroy(): void {
        this.subs.unsubscribe();
    }
}
